.Landing_Wrapper {
    margin-bottom: 50px;
    // font-family: sans-serif;
    font-family: 'Questrial', sans-serif;
    padding-left: 70px;
    padding-right: 70px;

    @media screen and (min-width:1000px) and (max-width:1400px) {
        padding: 30px;
        padding: 30px;
    }

    @media screen and (min-width:200px) and (max-width:1000px) {
        padding: 10px;
        padding: 10px;
    }

    .Landing_Inner_Wrapper {
        width: 100%;
        color: white;

        .row {
            width: 100%;
            color: white;

            .col-md-6 {
                padding: 0;
            }

            .BannerText {
                background-color: #B5B7D7;
                width: 100%;
                height: 860px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-left: 170px;
                padding-right: 170px;
                text-align: justify;
                color: #242660;

                @media screen and (max-width:769px) {
                    height: max-content !important;
                    padding-top: 40px;
                    padding-bottom: 40px;
                }

                @media screen and (min-width:1000px) and (max-width:1400px) {
                    height: 660px;
                }

                @media screen and (min-width:400px) and (max-width:1000px) {
                    height: 460px;
                }

                @media screen and (min-width:200px) and (max-width:400px) {
                    height: 360px;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @media screen and (min-width:1000px) and (max-width:1400px) {
                    padding-left: 70px;
                    padding-right: 70px;
                }

                @media screen and (min-width:400px) and (max-width:1000px) {
                    padding-left: 30px;
                    padding-right: 30px;
                }

                h1 {
                    font-size: 70px;
                    color: #242660;

                    @media screen and (min-width:1000px) and (max-width:1400px) {
                        font-size: 40px;
                    }

                    @media screen and (min-width:400px) and (max-width:1000px) {
                        font-size: 30px;
                    }

                    @media screen and (min-width:200px) and (max-width:400px) {
                        font-size: 25px;
                    }
                }

                p {
                    font-size: 30px;

                    @media screen and (min-width:1000px) and (max-width:1400px) {
                        font-size: 20px;
                    }

                    @media screen and (min-width:400px) and (max-width:1000px) {
                        font-size: 16px;
                    }

                    @media screen and (min-width:200px) and (max-width:400px) {
                        font-size: 13px;
                    }
                }

                .Self_content {
                    font-size: 20px;

                    @media screen and (min-width:1100px) and (max-width:1400px) {
                        font-size: 17px;
                    }

                    @media screen and (min-width:1400px) and (max-width:1600px) {
                        font-size: 15px;
                    }

                    @media screen and (min-width:800px) and (max-width:1100px) {
                        font-size: 13px;
                    }

                    @media screen and (min-width:769px) and (max-width:800px) {
                        font-size: 12px;
                    }

                    @media screen and (min-width:200px) and (max-width:769px) {
                        font-size: 13px;
                    }
                }

                .sub_content {
                    margin-top: -17px;
                    font-size: 25px;
                    margin-bottom: 50px;

                    @media screen and (min-width:1000px) and (max-width:1400px) {
                        font-size: 19px;
                    }

                    @media screen and (min-width:400px) and (max-width:1000px) {
                        font-size: 15px;
                    }

                    @media screen and (min-width:200px) and (max-width:400px) {
                        font-size: 13px;
                    }

                    @media screen and (max-width:769px){
                        margin-top: -9px;
                    }
                }
            }

            .BannerText1 {
                background-color: #B5B7D7;
                width: 100%;
                height: 450px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-left: 170px;
                padding-right: 170px;
                text-align: justify;
                color: #242660;

                @media screen and (min-width:200px) and (max-width:400px) {
                    height: 260px;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                @media screen and (min-width:1000px) and (max-width:1400px) {
                    padding-left: 70px;
                    padding-right: 70px;
                }

                @media screen and (min-width:400px) and (max-width:1000px) {
                    padding-left: 30px;
                    padding-right: 30px;
                }

                h1 {
                    // font-size: 70px;
                    color: #242660;
                }

                p {
                    font-size: 30px;

                    @media screen and (min-width:1000px) and (max-width:1400px) {
                        font-size: 20px;
                    }

                    @media screen and (min-width:400px) and (max-width:1000px) {
                        font-size: 16px;
                    }

                    @media screen and (min-width:200px) and (max-width:400px) {
                        font-size: 13px;
                    }
                }

                .Self_content {
                    font-size: 20px;
                }

                .sub_content2 {
                    font-size: 14px;
                    text-decoration: none;
                }

                .sub_content2 {}

                .sub_content {
                    margin-top: -17px;
                    font-size: 25px;
                    margin-bottom: 50px;
                }
            }

            .LoderImg {
                background-image: url(../assets/Landing.webp);
                background-repeat: no-repeat;
                // background-position: ;
                background-size: 100% 100%;
                width: 100%;
                height: 860px;

                @media screen and (min-width:1000px) and (max-width:1400px) {
                    height: 660px;
                }

                @media screen and (min-width:400px) and (max-width:1000px) {
                    height: 460px;
                }

                @media screen and (min-width:200px) and (max-width:400px) {
                    height: 300px;
                }
            }

            .LoderImg1 {
                background-image: url(../assets/Landing1.webp);
                background-repeat: no-repeat;
                // background-position: ;
                background-size: 100% 100%;
                width: 100%;
                height: 860px;

                @media screen and (min-width:1000px) and (max-width:1400px) {
                    height: 660px;
                }

                @media screen and (min-width:400px) and (max-width:1000px) {
                    height: 460px;
                }

                @media screen and (min-width:200px) and (max-width:400px) {
                    height: 300px;
                }
            }

            .LoderImg2 {
                background-image: url(../assets/gettouch.webp);
                background-repeat: no-repeat;
                // background-position: ;
                background-size: 100% 100%;
                width: 100%;
                height: 450px;

                @media screen and (min-width:200px) and (max-width:500px) {
                    height: 260px;
                }
            }

            .button_Enquire {
                width: 200px;
                padding: 20px;
                color: #B5B7D7;
                background-color: #242660;
                border: 0px;
                font-size: 18px;
                margin-top: 40px;
                font-weight: 500;

                @media screen and (min-width:1000px) and (max-width:1400px) {
                    font-size: 16px;
                    padding: 15px;
                    width: 160px;
                }

                @media screen and (min-width:200px) and (max-width:1000px) {
                    font-size: 14px;
                    padding: 7px;
                    width: 120px;
                }
            }
        }
    }
}
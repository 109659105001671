@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
.Listing_Wrapper{
    margin-top: 50px;
    background-color: #B5B7D7;

    .Listing_Inner_Wrapper{
        padding-top: 150px;
        padding-bottom: 150px;
        text-align: left;
        padding-left: 250px;
        
        @media screen and (max-width:600px){
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0;
        }
        h1{
            font-family: 'Syne', sans-serif;
            font-size: 60px;
            font-weight: 500;
            color: #121331;
        }
    }
}
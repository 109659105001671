.cards-wrapper {
  font-family: 'Questrial', sans-serif;
  padding-left: 50px;
  padding-right: 50px;

  @media screen and (min-width:1000px) and (max-width:1400px) {
    padding: 30px;
    padding: 30px;
  }

  @media screen and (min-width:200px) and (max-width:1000px) {
    padding: 10px;
    padding: 10px;
  }


  .row {
    .col-xl-3.col-md-6.col-sm-6.col-xs-12 {
      display: flex;
      gap: 39px;
      background-color: #121331;
      padding: 30px;

      .card {
        width: 100%;
        border: none;
        border-radius: 0px;
        background-color: #202258;
        color: #b5b7d7;

        .card-img-top {
          height: auto;
          width: 100%;
          border: 0px;
        }

        .card-body1 {
          // border: 1px solid red;
          text-align: left;
          padding: 20px;
          width: 90%;
          margin-left: 20px;

          @media screen and (min-width:200px) and (max-width:1000px){
            h4{
              font-size: 18px;
            }

            h5{
              font-size: 16px;
            }
          }
        }

        .card-body2 {
          // border: 1px solid greenyellow;
          text-align: justify;
          padding-left: 20px;
          width: 90%;
          margin-left: 20px;
          margin-bottom: 12px;


          p {
            @media screen and (min-width:200px) and (max-width:1400px) {
              font-size: 14px;
              // color: red;
            }
          }

        }
      }


    }
  }
}
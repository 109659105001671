.Header {
    // font-family: sans-serif;
    font-family: 'Questrial', sans-serif;
    margin-top: 100px;
    color: #B5B7D7;
    margin-bottom: 100px;

    h1 {
        font-size: 50px;

        @media screen and (max-width:500px){
            font-size: 35px;
        }
    }


    .body-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 100px;

        @media screen and (max-width:769px) {
            flex-direction: column;
        }

        .InnerCard {

            img {
                margin-bottom: 20px;

                @media screen and (min-width:200px) and (max-width:1400px) {
                    //     font-size: 15px;
                    //    //  width: ;
                    width: 20px;
                    height: 20px;
                }
            }

            width: 20%;
            border: 1px solid #fff;
            padding: 50px;
            font-size: 18px;
            color: #B5B7D7;
            text-align: center;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // flex-direction: column;
            height: 600px;

            @media screen and (min-width:1000px) and (max-width:1400px) {
                font-size: 16px;
                padding: 30px;
                //  width: ;
            }

            @media screen and (min-width:700px) and (max-width:1000px) {
                font-size: 14px;
                padding: 20px;
                //  width: ;
            }

            @media screen and (min-width:200px) and (max-width:769px) {
                font-size: 12px;
                padding: 10px;
                width: 100%;
                height: 300px;
                // flex-direction: row;
                //  width: ;
            }
        }
    }
}
// @import url(../assets/FontFamily/avenir_ff/AvenirLTStd-Black.otf);

.Navbar_Wrapper {
    // font-family: ;
    // font-family: sans-serif;
    font-family: 'Questrial', sans-serif;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (min-width:1000px) and (max-width:1400px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @media screen and (min-width:200px) and (max-width:1000px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    svg{
        width: 25px;
        height: 25px;
    }

    // .Navbar_Wrapper_back{
    //     width: 97%;
    //     background-color: rgba(0,0,0,0.5);
    //     height: 100%;
    //     position: fixed;    
    //     margin-left: -10px;
    //     padding-left: 49%;
    // }

    .Navbar_Inner_Wrapper {
        height: 53px;
        padding: 15px;
        // display: ;
        margin-bottom: 27px;

        // @media screen and (max-width:700px){
        //     padding: 3px;
        // }

        .Img_Logo {
            float: left;
        }

        .Nav_Items {
            float: right;
            color: rgb(181, 183, 215);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 53px;
            padding-right: 53px;

            @media screen and (max-width:700px) {
                padding: 3px;
            }

            span {
                cursor: pointer;
                margin-left: 30px;
                font-size: 14px;

                @media screen and (max-width:660px) {
                    margin-left: 12px;
                    font-size: 11px;
                }
            }
        }
    }

    .Navbar_Inner_Wrapper1 {
        // height: 53px;
        // padding: 15px;
        // display: ;
        // margin-bottom: 27px;
        background-color: rgba(0,0,0,0.5);
        width: 96%;
        margin-left: -10px;
        position: absolute;
        height: 100%;
        float: right;
        z-index: 999;
        top: 0;

        // @media screen and (max-width:700px){
        //     padding: 3px;
        // }

        .Img_Logo {
            float: left;
        }

        .Nav_Items {
            float: right;
            // color: rgb(181, 183, 215);
            color: black;
            display: flex;
            // font-size: 14px;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            margin-top: -10px;
            margin-right: -19px;
            // height: 53px;
            // padding-right: 53px;
            background-color: white;
            width: 70%;
            height: 100%;
            // margin-right: 40px;

            @media screen and (max-width:700px) {
                padding: 3px;
            }

            span {
                cursor: pointer;
                margin-left: 30px;
                font-size: 15px;
                margin-top: 10px;

                // @media screen and (max-width:660px) {
                //     margin-left: 12px;
                //     font-size: 11px;
                // }
            }
        }
    }
}

.footer_wrapper {
    border-top: 2px solid;
    margin: 0;
    padding: 0;
    // margin-bottom: 10px;
    font-family: 'Questrial', sans-serif;

    .Popup{
       position: absolute;
       padding: 4px;
       padding-left: 10px;
       padding-right: 10px;
       border: 1px solid;
       background-color: #fff;
       color: black;
       left: 50%;
       transform: translate(-50%);
    }

    .footer{
        position: relative;
        bottom: 10px;
        left: 0;
        width: 100%;
        background-color: #121331;
        text-align: center;
        color: #f4f4f4;
        // margin-bottom: 10px;


        .company-name{
            font-size: 18px;
            margin-top: 0.5rem;
        }
    }
}


.icons{
    padding-top: 1rem;
    // padding: 10px;

    .footer-email{
        margin-bottom: 10px;
    }

    p{
        padding: 0;
        margin: 0;
    }

    .gmail{
        color: #fff;
        font-size: 16px;
        font-weight: lighter;
        
        text-decoration: none;
    }

    .social-media {
        text-decoration: none;
    }
}

// .icons a{
//     text-decoration: none;
//     font-size: 2rem;
//     margin: 0.5rem;
//     color: #f4f4f4;
// }

.company-name{
    font-size: 18px;
    margin-top: 0.5rem;
}

@media (max-width: 500px){
    html{
        font-size: 50%;
    }
}    

@media(min-width: 501px) and (max-width: 768px){
    html{
    font-size: 50%;
    }
}    
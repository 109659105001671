.OurTeam_Wrapper {
    padding-left: 70px;
    padding-right: 70px;
    color: #B5B7D7;
    font-family: 'Questrial', sans-serif;

    h1 {
        font-size: 50px;

        @media screen and (max-width:500px){
            font-size: 35px;
        }
    }

    @media screen and (min-width:1000px) and (max-width:1400px){
        padding: 30px;
        padding: 30px;
    }

    @media screen and (min-width:200px) and (max-width:1000px){
        padding: 10px;
        padding: 10px;
    }

    .OurTeam_Inner_Wrapper {

        font-family: 'Questrial', sans-serif;
        margin-top: 100px;
        color: #B5B7D7;
        background-color: #B5B7D7;
        margin-bottom: 100px;

        h1 {
            font-size: 50px;
        }

        .col-lg-3.col-md-4.col-sm-6.col-xs-12{
             margin-bottom: 20px;
        }
        .body-img{
            background-color: rgba(0, 0, 0, 0);
            border-radius: 0px;
            border: 0px;
            img{

                width: 100%;
                // height: 00px;

                @media screen and (min-width:1000px){
                    height: 250px;
                }

                @media screen and (min-width:770px) and (max-width:1000px){
                    height: 230px;
                }
            }
        }

        .footer-content{
            border: 0px;
            // margin-top: -30px;
            margin-top: -40px;
            background-color: rgba(0, 0, 0, 0);
            padding-left: 20%;
            // padding-right: 10%;

            h6{
                font-weight: 600;
                font-size: 20px;
            }

            p{
                font-size: 16px;
            }

            @media screen and (min-width:100px) and (max-width:769px){
                font-size: 14px;
                margin-top: -40px;

                h6{
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .footer_inside_content{
                padding-top: 4%;
                border: 0px;
                border-radius: 0px;
                text-align: left;
                padding-left: 10%;
                background-color: #121331;
                height: 110px;
            }
        }
    }
}